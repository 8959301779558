.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.transition {
  transition: opacity 0.5s ease-in-out;
}



/* Base button styling */
/* Adding transition duration variable for easy tweaking */
.custom-button {
  
  padding: 0.5rem 1rem;
  background-color: #0D1B2A;
  color: white;
  font-weight: bold;
  border-radius: 0.25rem;
  --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}

.custom-button:hover {
  background-color: #778DA9;
  color: #1B263B;
  transform: scale(1.25);
}

.vertical-line {
        border-left: 1px solid white; /* Adjust the color and width as needed */
        height: 200px; /* Adjust the height of the line */
        margin: 0 10px; /* Adjust the margin as needed */
    }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.icon-grid {
  display: grid;
  grid-template-columns: repeat(2, minmax(72px, 1fr)); /* Updated to 3 columns */
  gap: 1rem;
}

@media (max-width: 768px) {
  .icon-grid {
    grid-template-columns: repeat(2, minmax(48px, 1fr));
  }
}


.icon-grid2 {
  display: grid;
  grid-template-columns: repeat(2, minmax(72px, 1fr)); /* Updated to 3 columns */
  gap: 1rem;
}

@media (max-width: 768px) {
  .icon-grid2 {
    grid-template-columns: repeat(2, minmax(48px, 1fr));
  }
}

.icon-grid3 {
  display: grid;
  grid-template-columns: repeat(5, minmax(72px, 1fr)); /* Updated to 3 columns */
  gap: 1rem;
}

@media (max-width: 768px) {
  .icon-grid3 {
    grid-template-columns: repeat(5, minmax(48px, 1fr));
  }
}



.stats-section {
  background-color: #fff;
}

.stat-item {
  border: 1px solid #778DA9; 
  /* Tailwind's gray-200 color */
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease;
}

.stat-item:hover {
  transform: translateY(-10px);
  color:  #1B263B; 
}

.number {
  transition: color 0.3s ease;
}
.label {
  transition: color 0.3s ease;
}

.stat-item:hover .number {
  color:  #1B263B; 
  /* color: #ea580c;  */
  /* Tailwind's orange-500 color */
}
.stat-item:hover .label {
  color:  #1B263B; 
  /* color: #ea580c;  */
  /* Tailwind's orange-500 color */
}

.service {
  transition: transform 0.3s ease;
}


.service:hover {
  
  transform: translateY(-16px);
}

.allproduct {
  transition: transform 0.3s ease;
}


.allproduct:hover {
  
  transform: translateY(-10px);
}

.nav-item {
  transition: transform 0.3s ease;
}


.nav-item:hover {
  
  transform: translateY(-6px);
}





#arrow {
  fill: #E0E1DD;
  transition: fill 0.3s ease; /* Optional: smooth transition */
  --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}

#arrow:hover {
  fill: #0D1B2A; /* Change this color to your desired hover color */
  
}

#monitor:hover {
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
  transform: scale(1.25);
  
}
#monitor {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
  --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
  
}
#apple:hover {
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
  fill: #FFFFFF;
  transform: scale(1.25);
  
}
#apple {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
  fill: #0D1B2A; /* Change this color to your desired hover color*/
  --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
  
}
#windows:hover{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;
    transform: scale(1.25);

}
#windows {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

  
}
#audio:hover{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;
    transform: scale(1.25);

}
#audio {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

  
}
#software:hover{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;
    transform: scale(1.25);

}
#software {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}

#amcimage {
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}


@media (max-width: 600px) {
  #apple:hover {
    transform: scale(1.1); /* Adjust the scale for small screens */
  }
  #windows:hover {
    transform: scale(1.1); /* Adjust the scale for small screens */
  }
  #monitor:hover {
    transform: scale(1.1); /* Adjust the scale for small screens */
  }
  #audio:hover {
    transform: scale(1.1); /* Adjust the scale for small screens */
  }
  #software:hover {
    transform: scale(1.1); /* Adjust the scale for small screens */
  }
}



#call1:hover{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;

}
#call1 {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}
#email1:hover{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;

}
#email1 {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}
#address1:hover{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;

}
#address1 {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}
#address2{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

}
#address2:hover {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
}
#call2{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

}
#call2:hover {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
}
#email2{
  stroke: #FFFFFF; /* Change this color to your desired hover color*/
    fill: #FFFFFF;
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

}
#email2:hover {
  stroke: #0D1B2A; /* Change this color to your desired hover color*/
    fill: #0D1B2A; /* Change this color to your desired hover color*/
}
.handler{
 
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

}
.handler:hover {
  transform: scale(1.5);
}
.software{
 
    --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;

}



#submitamc {
  color:white;
  --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
  
}
#submitamc:hover{
  color: #0D1B2A;
    transform: scale(1.25);

}

/* App.css */
.monitor-clicked {
  stroke: #0D1B2A; /* Change this color to your desired click color */
}


.btn-back{
  background:#1B263B;
  height: 0px;
}

#arrowrev {
  fill: #E0E1DD;
  transition: fill 0.3s ease; /* Optional: smooth transition */
  /* transform: rotate(180deg); */
  --transition-duration: 300ms;
  transition: transform var(--transition-duration) ease-in-out, background-color var(--transition-duration) ease-in-out, color var(--transition-duration) ease-in-out;
}

#arrowrev:hover {
  fill: #0D1B2A; /* Change this color to your desired hover color */
}

@media (max-width: 600px) {
  #arrow,
  #arrow:hover,
  #arrowrev,
  #arrowrev:hover {
    fill: #FFFFFF; /* Change fill to white for small screens */
    
  }
}





/* styles.css */
.animated-gradient {
  /* width:60vw; */
  /* height: 12vh; */
   /* Full viewport height */
  background: linear-gradient(270deg, #ff6ec4, #7873f5, #55efc4, #ff6ec4);
  background-size: 600% 600%;
  animation: gradientAnimation 12s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


  /* @keyframes scroll-left-right {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(calc(+5% + -34vw));
  }
  100% {
    transform: translateX(0);
  }
} */


/* Custom scrollbar for the entire webpage */
body::-webkit-scrollbar {
  width: 12px; /* Adjust the width of the vertical scrollbar */
  height: 8px; /* Adjust the height of the horizontal scrollbar */
}

body::-webkit-scrollbar-track {
  background: #f0f2ef; 
}

body::-webkit-scrollbar-thumb {
  background: #0D1B2A; 
}

body::-webkit-scrollbar-thumb:hover {
  background: #415A77; 
}

/* Optional: Ensure this styling applies to all scrollable elements as well */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f2ef;
}

*::-webkit-scrollbar-thumb {
  background: #415A77;
}

*::-webkit-scrollbar-thumb:hover {
  background: #0D1B2A;
}





/* Custom scrollbar */
.overflow-x-auto {
  overflow-x: auto; /* Ensure horizontal scrolling is enabled */
  white-space: nowrap; /* Prevent wrapping of content */
}

.overflow-x-auto::-webkit-scrollbar {
  height: 8px; /* Adjust the height of the scrollbar */
}

.overflow-x-auto::-webkit-scrollbar-track {
  background: #f0f2ef; 
}

.overflow-x-auto::-webkit-scrollbar-thumb {
  background: #415A77; 
}

.overflow-x-auto::-webkit-scrollbar-thumb:hover {
  background: #0D1B2A; 
}


/* Animation for container and scrollbar thumb */
.lets-move {
  display: flex;
  animation: scroll-left-right 30s linear infinite;
}

/* Adjust thumb position based on animation */
.lets-move::-webkit-scrollbar-thumb {
  position: absolute;
  left: 50%; /* Adjust position as needed */
  transform: translateX(-50%);
  animation: scroll-left-right 30s linear infinite;
  background: #888; /* Same as original thumb color */
}

/* Hover effect on thumb */
.lets-move::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* Base tooltip styles */
.tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  font-size: small;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  bottom: -10%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show tooltip on hover */
button:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

/* Adjust tooltip for small screens */
@media screen and (max-width: 768px) {
  .tooltip-text {
    visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  font-size: small;
  border-radius: 6px;
  padding: 2px 0;
  position: absolute;
  z-index: 1;
  bottom: -10%; /* Position the tooltip above the button */
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  }
}



.cs-1 {
    background-color: #0D1B2A;
}
.cs-2 {
    background-color: #1B263B;
}
.cs-3 {
    background-color: #415A77;
}
/* .cs-3 {
    background-color: #fdfcdc;
} */
.cs-4 {
    background-color: #778DA9;
}
.cs-5 {
    background-color: #E0E1DD;
}
.cs-6 {
    background-color: #f07167;
}

