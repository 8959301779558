@tailwind base;
@tailwind components;
@tailwind utilities;


.home-container {
  height: 100vh; /* Set the height to 100% of the viewport height */
  display: flex; /* Use flexbox for centering content vertically */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
}


/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar handle */
  border-radius: 5px; /* Rounded corners */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color on hover */
}





body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
